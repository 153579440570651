import React from "react";
import { Carousel } from "react-responsive-carousel"; // Install this: npm install react-responsive-carousel
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import "./Landingpage.css";
import project1 from "./project1.png";
import project2 from "./project2.png";
import project3 from "./project3.png";

const AboutSection = () => {
    const projects = [
        {
          img: project1, // Replace with the actual image path for project1
          description: "Вертикальный токарный станок с ЧПУ YV1200ATC (26 тонн). Маршрут: Тайвань – Дубай – Санкт-Петербург. Стоимость: 950 600 AED.",
        },
        {
          img: project2, // Replace with the actual image path for project2
          description: "Сепаратор SB-2.5x9.0.000.000 (21 тонна). Маршрут: Липецк – Крым, село Победное. Стоимость: 7 776 558 руб.",
        },
        {
          img: project3, // Replace with the actual image path for project3
          description: "Электроэрозионный станок CUT C600 – 2 шт. (9.9 тонны). Маршрут: Дунгуань (Китай) – Забайкальск – Санкт-Петербург. Стоимость: 1 980 000 юаней.",
        },
      ];

  return (
    <section id="about" className="section second-section">
      <div className="about-container">
        {/* Left Side: Carousel */}
        <div className="carousel-container">
          <Carousel
            showArrows={true}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={5000}
            stopOnHover={true}
          >
            {projects.map((project, index) => (
              <div key={index} className="carousel-slide">
                <img src={project.img} alt={`Project ${index + 1}`} />
                <p className="legend">{project.description}</p>
              </div>
            ))}
          </Carousel>
        </div>

        {/* Right Side: Company Description */}
        <div className="description-container">
          <h2>Про нас</h2>
          <p>
            Наша компания оказывает транспортно-экспедиторские услуги по международным и внутрироссийским перевозкам грузов любой степени сложности, всех отраслей промышленности (от стандартных грузов до негабаритных), в том числе перевозки проектных грузов, различными видами транспорта. Мы охватываем рынки РФ, стран ЕС и стран Восточно-Азиатского региона.
          </p>
          <p>
            При оказании экспедиторских услуг мы организуем перевозку грузов от двери склада грузоотправителя до двери склада грузополучателя. В перечень наших услуг входит:
          </p>
          <ul>
            <li>Разработка по поручению клиента маршрута перевозки груза;</li>
            <li>Заключение договоров с другими участниками перевозочного процесса;</li>
            <li>Оформление транспортных документов;</li>
            <li>Страхование грузов;</li>
            <li>Информирование грузоотправителей о продвижении грузов;</li>
            <li>Получение разрешений на перевозку опасных и негабаритных грузов;</li>
            <li>Другие услуги по поручению клиента.</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
