import logo from './logo.svg';
import background from './backgroundImg.jpg';
import './App.css';
import LandingPage from './Landingpage';

function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
